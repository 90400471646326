import * as React from 'react'
import { ReactNode } from 'react';
import { BankDetailsType } from '../contexts/ContextTypes';

export interface Props {
  children?: ReactNode,
  bankDetails: BankDetailsType,
}

export default function BankDetailsView({children, bankDetails} : Props) {
  return (
    <div>
      <ul>
        {bankDetails.reference && <li>Reference: {bankDetails.reference}</li>}
        <li>Full Name: {bankDetails.fullName}</li>
        <li>Sort Code: {bankDetails.sortCode}</li>
        <li>Account Number: {bankDetails.accountNumber}</li>
      </ul>
    </div>
  )
}
