import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Button, Card, Form } from 'react-bootstrap';
import {
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getExpandedRowModel,
  ExpandedState,
} from '@tanstack/react-table'
import { useApprovedContext } from '../../contexts/ApprovedContext';
import { Status, UserFlyingDay } from '../../models/UserFlyingDay';
import BootstrapTable from '../../table/BootstrapTable';
import { useAuth } from '../../contexts/AuthContext';
import { FlightsColumnBuilder } from '../../table/FlightsColumnBuilder';
import useWindowSize from '../../utils/useWindowSize';
import BankDetailsView from '../BankDetailsView';
import { useDisplayedListsContext } from '../../contexts/DisplayedListsContext';
import { BankDetailsType } from '../../contexts/ContextTypes';

const BREAKPOINT = 576;

export default function Flights() {
  const { getUsersFlyingDays, markDaysAsPaid } = useApprovedContext();
  const { bankDetails } = useDisplayedListsContext();
  const { currentUser } = useAuth()
  const { width } = useWindowSize()

  const notesRef = useRef<HTMLInputElement>(null);

  const [usersFlyingDays, setUsersFlyingDays] = useState<Array<UserFlyingDay>>([]);
  useEffect(() => {
    setFlyingDays();
    async function setFlyingDays() {
      setUsersFlyingDays(currentUser ? await getUsersFlyingDays(currentUser.uid) : [])
    }
  }, [setUsersFlyingDays, getUsersFlyingDays, currentUser])

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const columnBuilder = new FlightsColumnBuilder();
  columnBuilder.addDate(width);
  if (width > BREAKPOINT) {
    columnBuilder.addP1P2();
    columnBuilder.addLaunches();
    columnBuilder.addFlightTime();
  }
  columnBuilder.addCost();
  columnBuilder.addStatus();

  const table = useReactTable({
    data: usersFlyingDays,
    columns: columnBuilder.getColumns(),
    state: {
      expanded,
    },
    getCoreRowModel: getCoreRowModel<UserFlyingDay>(),
    getPaginationRowModel: getPaginationRowModel(),
    enableMultiRowSelection: true,
    enableSubRowSelection: true,
    enableGrouping: true,
    getRowCanExpand: (row) => row.original instanceof UserFlyingDay,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel<UserFlyingDay>(),
    getSubRows: row => (row instanceof UserFlyingDay) ? row.flights : []
  })

  function addReferenceToBankDetails(bankDetails: BankDetailsType) : BankDetailsType {
    if (currentUser === null) throw new Error("Current user is null");
    if (currentUser.email === null) throw new Error("Current user email is null");

    let universityID = currentUser.email.substring(0, currentUser.email.indexOf("@"));
    bankDetails.reference = `MH GLI ${universityID}`;

    return bankDetails;
  }
  
  async function payDays(e : React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const flyingDays: Array<UserFlyingDay> = [];
    getSelectedUnpaidRows().forEach(row => {
      if (row.original instanceof UserFlyingDay)
        flyingDays.push(row.original) 
    })

    await markDaysAsPaid(
      flyingDays,
      (notesRef.current) ? notesRef.current.value : undefined
    );
    setUsersFlyingDays(currentUser ? await getUsersFlyingDays(currentUser.uid) : []);
    
    window.location.reload();
  }

  const getSelectedUnpaidRows = () => 
    table.getSelectedRowModel().rows.filter(
      row => (row.original instanceof UserFlyingDay) ? row.original.status === Status.Unpaid : false
    )
  return (
    <Card style={width > 576 ? { minWidth: "48em" } : {}}>
      <Card.Header>
        <h2 className='text-center'>Your Flying</h2>
        <p className="text-center mb-2">This is for billing purposes only and is not a replacement for your log book.</p>
      </Card.Header>
      <Card.Body className="d-flex justify-content-center row">
        {/* https://react-bootstrap-table.github.io/react-bootstrap-table2/ */}
        <BootstrapTable table={table}>
          <Form className='row' onSubmit={payDays}>
            <Form.Group>
              <Form.Label className='col'>Notes: </Form.Label>
              <Form.Control
                className='col'
                ref={notesRef}
              />
            </Form.Group>
            <Button
              className='col'
              type='submit'
              disabled={getSelectedUnpaidRows().length === 0}
            >Mark ({getSelectedUnpaidRows().length}) selected Day(s) as paid, with £{
                  getSelectedUnpaidRows().reduce(
                    (previous, row) => previous + ((row.original instanceof UserFlyingDay) ? row.original.cost : 0),
                    0
                  ) / 100
                } transferred</Button>
          </Form>
        </BootstrapTable>
      </Card.Body>
      <Card.Footer>
        To pay for your flights please select the days you wish to pay for, pay the shown amount to:
        <BankDetailsView bankDetails={addReferenceToBankDetails(bankDetails)}></BankDetailsView>
        & press the button.
      </Card.Footer>
    </Card>
  )
}
